<template>
  <list-template
    :current-page="page"
    :isCheckInputBox="false"
    :loading="loading"
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    @onChangePage="onCurrentChange"
    @onHandle="tableHandle"
    @onReset="onReset"
    @onSearch="onSearch"
    @onExportData="derived"
  ></list-template>
</template>

<script>

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading: false,
			condition: { // 搜索条件
				date_start: '',
				date_end: '',
				three: [ '', '' ],
				class_id: '',
				student_name: '',
				school_id: ''
			},
			tableData: [],
			total: 0,
			page: 1,
			schoolArr: [],
			classArr: [],
      searchConfig: [
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          value: 'id',
          label: 'name',
          change: (val,search) => {
            if(search.class_id) {
              search.class_id = ''
            }
            this.$_axios2('api/evaluation/result/get-range?school_id=' + val).then(res => {
              this.searchConfig[2].options = res.data.data.class
            })
          },
          options: []
        },
        {
          prop: 'class_id',
          placeholder: '筛选行政班',
          tag: 'select',
          value: 'id',
          label: 'name',
          options: []
        },
        {
          prop: 'three',
          tag: 'datePicker',
          property: {
            size: 'small',
            style: 'margin-right:20rem;',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        }
      ],
      tableConfig: [
        {prop: 'student', label: '学生姓名'},
        {prop: 'student_no', label: '学号'},
        {prop: 'class', label: '行政班'},
        {prop: 'teacher', label: '班主任姓名'},
        {prop: 'created_at', label: '谈话日期'},
        {prop: 'school', label: '校区'},
        {label: '操作', handle: true, width: '80rem', buttons: [{ type:"view", text:"查看" }]},
      ]
		}
	},
  methods: {
		onSearch(search) {
      this.condition = search
      if(search.three) {
        this.condition.date_start = search.three[0]
        this.condition.date_end = search.three[1]
      }
			this.searchData(this.page = 1)
		},
		onReset() {
			this.condition = {
				date_start: '',
				date_end: '',
				three: [ '', '' ],
				class_id: '',
				student_name: '',
				school_id: ''
			}
			this.searchData(this.page = 1)
		},
		tableHandle(row) {
			this.$router.push('./edit?id=' + row.id)
		},
    derived() {
      let data = JSON.parse(JSON.stringify(this.condition));
      delete data.three;
      this.$_axios2.get('api/work/teacher-class/download', {params: data}).then(res => {
        this.$tools.download('班主任交心谈话', res.data)
      })
    },
		searchData(val) {
			let data = JSON.parse(JSON.stringify(this.condition));
			delete data.three;
      this.loading = true
			this.$_axios2.get('api/work/teacher-class/talk?page=' + val, { params: data }).then(res => {
				if (res.data.data.list) {
					this.tableData = res.data.data.list
					this.total = res.data.data.page.total
				} else {
					this.tableData = []
					this.total = 0
				}
			}).finally(() => {
			  this.loading = false
			})
		},
		onCurrentChange(val) {
			this.searchData(this.page = val)
		},
	},
	created() {
		this.searchData(this.page)
		this.$_axios2('api/evaluation/result/get-range').then(res => {
			this.searchConfig[1].options = res.data.data.school
			this.searchConfig[2].options = res.data.data.class
		})
	}
}
</script>

<style scoped lang="scss">
.title {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 24rem;
}


::v-deep .el-table td.el-table__cell div {
	font-size: 14rem !important;
}
</style>
